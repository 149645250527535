import React from 'react';
import { AppProps } from 'next/app';

import 'react-accessible-accordion/dist/fancy-example.css';
import '@styles/main.css';

// eslint-disable-next-line react/jsx-props-no-spreading
const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
      <Component {...pageProps} />
  );
};

export default MyApp;
